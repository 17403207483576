import React from "react";

import { graphql } from "gatsby";
import AppBase from "../../components/AppBase";
import { Helmet } from "react-helmet";
import Link from "../../components/Link";

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const allCategories = data.blogPosts.group.map(item => item.fieldValue);
  return (
    <AppBase>
      <Helmet>
        <title>{"Jake's Blog - Tags"}</title>
      </Helmet>
      <div>
        <h1>All Tags</h1>
        <ul>
          {allCategories.map((category, index) => {
            return (
              <li key={index}>
                <Link to={`/blog/category/${category.toLowerCase()}`}>
                  {category}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </AppBase>
  );
}
export const query = graphql`
  query MyQuery {
    blogPosts: allMarkdownRemark {
      group(field: frontmatter___categories) {
        fieldValue
      }
    }
  }
`;
